import { set } from "lodash";

function useFormValidImpl() {
  const valid = ref<Record<string | number, boolean>>({});

  const isInvalid = computed(() => someFalse(Object.values(valid.value)));

  const isValid = computed(() => !isInvalid.value);

  const setValid = (key: string, value: boolean) =>
    set(valid.value, key, value);

  const getValid = (key: string) => valid.value[key] !== false;

  const useValid = (key?: string) => {
    const k = key || "default";
    return computed({
      set(value: boolean) {
        setValid(k, value);
      },
      get() {
        return getValid(k);
      },
    });
  };

  const clear = () => (valid.value = {});

  return {
    valid,
    isInvalid,
    isValid,
    setValid,
    getValid,
    useValid,
    clear,
  };
}

export function useFormValid() {
  const source = useFormValidImpl();

  const isRootValid = (name: string) => {
    const v = Object.entries(source.valid.value)
      .filter(([k]) => String(k).startsWith(name))
      .map(([_, v]) => v);

    return !someFalse(v);
  };

  const createProxy = (path: string | string[]) => {
    const p = Array.isArray(path) ? path : [path];
    const pKey = p.join("/");

    const sourceImpl = useFormValidImpl();
    watch(sourceImpl.isValid, (v) => source.setValid(pKey, v));

    const getValid = (key: string) => {
      const k = `${pKey}/${key}`;
      const v = Object.entries(source.valid.value);

      if (v.length < 1 && sourceImpl.valid.value[key] === false) {
        return false;
      }

      return isRootValid(k);
    };

    const setValid = (key: string, value: boolean) => {
      const k = `${pKey}/${key}`;
      source.setValid(k, value);
    };

    return {
      ...sourceImpl,
      getValid,
      setValid,
    };
  };

  const getValidRef = (path?: string | string[]) => {
    if (path) {
      return createProxy(path).valid;
    }
    return source.valid;
  };

  return {
    ...source,
    createProxy,
    getValidRef,
  };
}
